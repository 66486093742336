<template>
    <div id="banner">
        <div class="hidden md:inline">
            <template v-for="text in texts" :key="text.id">
                {{ $t(text.message) }}
                <span class="separator" v-if="text.id != 4"></span>
            </template>
        </div>

        <div class="whitespace-nowrap animate-marquee md:hidden">
            <template v-for="text in texts">
                {{ $t(text.message) }}
                <span class="separator"></span>
            </template>
        </div>

        <div class="absolute top-0 animate-marquee2 whitespace-nowrap md:hidden">
            <template v-for="text in texts">
                {{ $t(text.message) }}
                <span class="separator"></span>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            texts: [
                { id: 1, message: 'showcase.french_agency' },
                { id: 2, message: 'showcase.furniture' },
                { id: 3, message: 'showcase.products' },
                { id: 4, message: 'showcase.design_strategy' }
            ]
        }
    }
}
</script>