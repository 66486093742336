<template>
    <div class="col-span-full lg:col-span-2 flex mb-3 md:mb-0">
        <hr class="line-h2">
        <h2>{{ title }}</h2>
    </div>
</template>

<script>
export default {
    props: {
        title: String
    }
}
</script>