<template>
    <hr class="line-expertise" :class="{ 'selected': isHighlighted(0) }">
    <template v-for="bloc in blocs" :key="bloc.id">
        <expertise-component @click="toggle(bloc)" :number="formatNumber(bloc.id)" :title="$t(bloc.title)" />
        <media-and-text-component v-if="bloc.expanded" :img="bloc.img">
            <span v-html="`<p>` + $t(bloc.text) + `</p>`"></span>
        </media-and-text-component>
        <hr class="line-expertise" :class="{ 'selected': isHighlighted(bloc.id) }">
    </template>
</template>

<script>
export default {
    methods: {
        formatNumber: function (value) {
            return '0' + value
        },
        blocId: function (id) {
            return 'bloc-' + id
        },
        toggle: function (bloc) {
            bloc.expanded = !bloc.expanded
        },
        isHighlighted: function(upperId) {
            var searchIds = [upperId, upperId+1]
            var relatedBlocs = this.blocs.filter((bloc) => searchIds.includes(bloc.id) )
            return relatedBlocs.find((bloc) => bloc.expanded )
        }
    },
    data() {
        return {
            'blocs': [
                {
                    id: 1,
                    title: 'showcase.design_creation',
                    img: 'creation_design.png',
                    text: 'showcase.design_creation_description',
                    expanded: false,
                },
                {
                    id: 2,
                    title: 'showcase.3d_modeling',
                    img: 'modelisation_3D.png',
                    text: 'showcase.3d_modeling_description',
                    expanded: false,
                },
                {
                    id: 3,
                    title: 'showcase.3d_printing',
                    img: 'impression_3D.png',
                    text: 'showcase.3d_printing_description',
                    expanded: false,
                },
                {
                    id: 4,
                    title: 'showcase.augmented_reality',
                    img: 'realite_augmentee.png',
                    text: 'showcase.augmented_reality_description',
                    expanded: false,
                },
                {
                    id: 5,
                    title: 'showcase.partner_area',
                    img: 'espace_partenaire.png',
                    text: 'showcase.partner_area_description',
                    expanded: false,
                },
                {
                    id: 6,
                    title: 'showcase.environmental_approach',
                    img: 'demarche_environnementale.png',
                    text: 'showcase.environmental_approach_description',
                    expanded: false,
                }
            ]
        };
    },
};
</script>