<template>
    <img class="col-span-full lg:col-start-3 lg:col-end-7 mb-4 w-full" :class="imageSizeClasses" :src="path" alt="">

    <div class="col-span-full lg:col-start-7 lg:col-end-13" :class="textSizeClasses">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        img: String,
        smallImage: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        path() {
            return '/img/' + this.img
        },
        imageSizeClasses() {
            return this.smallImage ? "md:col-start-1 md:col-end-3" : "md:col-start-1 md:col-end-4"
        },
        textSizeClasses() {
            return this.smallImage ? "md:col-start-3 md:col-end-7" : "md:col-start-4 md:col-end-7"
        }
    }
}
</script>