// Warning: only works with a single honeypot per page
export function honeypot(body) {
    var honeypot = document.querySelector("input[id^='my_name_']")
    var honeypot_value = honeypot.getAttribute('value')
    if (honeypot_value == '') {
        return {}
    }

    var honeypot_id = honeypot.getAttribute('id')
    let input = {}
    input[honeypot_id] = honeypot_value
    return input
}