import './bootstrap'
import '../css/app.css'

import { createApp, h } from 'vue'
import { i18nVue } from 'laravel-vue-i18n'
import * as Sentry from "@sentry/vue"

import BannerComponent from "./components/BannerComponent.vue"
import ContactFormComponent from "./components/ContactFormComponent.vue"
import CoordComponent from "./components/CoordComponent.vue"
import DropdownLang from "./Components/DropdownLang.vue"
import DropdownSettings from "./Components/DropdownSettings.vue"
import FooterComponent from "./components/FooterComponent.vue"
import ExpertiseComponent from "./components/ExpertiseComponent.vue"
import ExpertiseListComponent from "./components/ExpertiseListComponent.vue"
import HomeSectionTitleComponent from "./components/HomeSectionTitleComponent.vue"
import InputComponent from "./components/InputComponent.vue"
import MediaAndTextComponent from "./components/MediaAndTextComponent.vue"
import TextareaComponent from "./components/TextareaComponent.vue"


const app = createApp({})

app
    .component("banner-component", BannerComponent)
    .component("contact-form-component", ContactFormComponent)
    .component("coord-component", CoordComponent)
    .component("dropdown-lang", DropdownLang)
    .component("dropdown-settings", DropdownSettings)
    .component("footer-component", FooterComponent)
    .component("expertise-component", ExpertiseComponent)
    .component("expertise-list-component", ExpertiseListComponent)
    .component("home-section-title-component", HomeSectionTitleComponent)
    .component("input-component", InputComponent)
    .component("media-and-text-component", MediaAndTextComponent)
    .component("textarea-component", TextareaComponent)

app.use(i18nVue, {
    resolve: async lang => {
        const langs = import.meta.glob('../../lang/*.json')
        if (lang.includes("php_")) {
            return await langs[`../../lang/${lang}.json`]()
        }
    }
})

Sentry.init({
    app,
    dsn: "https://f173130bd2e0b3c511313b39f86acc23@o4507730823282688.ingest.de.sentry.io/4507730884493392",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })

app.mount("#app")