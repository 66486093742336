<template>
    <div class="mb-6" :class="sizeClass">
        <label :for="id" class="block mb-2 text-sm" :class="{ required: required }">{{ label }}</label>

        <textarea :id="id" :value="value" @input="$emit('update:value', $event.target.value)"
            class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 h-32" 
            placeholder="Votre message" required></textarea>
            
        <p v-if="error != ''" class="error-message">{{ error }}</p>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        required: Boolean,
        fullSize: Boolean,
        error: {
            Type: String,
            default: ''
        }
    },
    computed: {
        sizeClass() {
            return this.fullSize ? "col-span-2" : "col-span-1"
        }
    }
}
</script>