<template>
    <div class="flex h-[26px] mb-8">
        <img class="w-[24px] h-[26px] mr-4" :src="path" alt="">
        <span class="align-middle">{{ text }}</span>
    </div>
</template>

<script>
export default {
    props: {
        img: String,
        text: String
    },
    computed: {
        path() {
            return '/img/' + this.img
        }
    }
}
</script>