<template>
    <div class="mb-6" :class="sizeClass">
        <label :for="id" class="block mb-2 text-sm" :class="{ required: required }">{{ label }}</label>
        <input :type="type" :id="id" :value="value" @input="$emit('update:value', $event.target.value)"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" required />
            
        <p v-if="error != ''" class="error-message">{{ error }}</p>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'text',
            validator(value, props) {
                return ['text', 'email', 'tel'].includes(value)
            }
        },
        required: Boolean,
        fullSize: Boolean,
        error: {
            type: String,
            default: ''
        }
    },
    computed: {
        sizeClass() {
            return this.fullSize ? "col-span-2" : "col-span-1"
        }
    }
}
</script>