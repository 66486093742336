<template>
    <div class="expertise-header" :class="{ 'selected': active }" @click="toggle">
        <div class="my-4 flex justify-between">
            <div>
                <span>{{ number }}</span>
                <h3 class="inline">{{ title }}</h3>
            </div>
            <img class="w-[25px] h-[25px] my-auto duration-150 origin-center grayscale" :class="{ 'rotate-45 grayscale-0': active }" :src="crossPath" alt="Afficher le détail">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        number: String,
        title: String
    },
    data() {
        return {
            crossPath: 'img/cross.svg',
            active: false
        }
    },
    methods: {
        toggle() {
            this.active = !this.active
        }
    }
}
</script>