export function post(url, body) {

    const options = {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            'X-CSRF-TOKEN': csrf()
        },
        body: JSON.stringify(body),
    }
        
    return fetch(url, options)
        .then((response) => {
            if (response.ok || response.status == 422) {
                return response.json();
            }
            throw new Error('Something went wrong');
        })
}

function csrf() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
}