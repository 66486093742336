<template>
    <slot></slot>
    <input-component :label="$t('form.last_name')" id="last_name" v-model:value="inputs.last_name.value" :error="inputs.last_name.error" :required="true" />
    <input-component :label="$t('form.first_name')" id="first_name" v-model:value="inputs.first_name.value" :error="inputs.first_name.error" :required="true" />
    <input-component :label="$t('form.email')" id="email" v-model:value="inputs.email.value" :error="inputs.email.error" :required="true" :full-size="true" type="email" />
    <input-component :label="$t('form.company')" id="company" v-model:value="inputs.company.value" :error="inputs.company.error" />
    <input-component :label="$t('form.telephone')" id="tel" v-model:value="inputs.tel.value" :error="inputs.tel.error" type="tel" />
    <textarea-component :label="$t('form.contact_message')" id="message" v-model:value="inputs.message.value" :error="inputs.message.error" :required="true" :full-size="true" />
    <button @click="send" :disabled="sending">
        <div v-if="sending" class="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] text-surface"
            role="status">
            <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]" >Loading...</span>
        </div>
        {{ $t('form.send') }}
    </button>

    <div v-if="success" class="col-span-full p-4 mt-4 text-sm text-green-800 rounded-lg bg-green-50" role="alert">
        {{ $t('form.success') }}
    </div>
    <div v-if="unavailable" class="col-span-full p-4 mt-4 text-sm text-red-800 rounded-lg bg-red-50" role="alert">
        {{ $t('form.failure') }}
    </div>
</template>

<script>
import { post } from './../fetch.js'
import { honeypot } from './../honeypot.js'
const default_inputs = {
    last_name: {
        value: '',
        error: ''
    },
    first_name: {
        value: '',
        error: ''
    },
    email: {
        value: '',
        error: ''
    },
    company: {
        value: '',
        error: ''
    },
    tel: {
        value: '',
        error: ''
    },
    message: {
        value: '',
        error: ''
    },
}

export default {
    data() {
        return {
            success: false,
            unavailable: false,
            sending: false,
            inputs: {},
        }
    },
    created() {
        this.inputs = structuredClone(default_inputs)
    },
    computed: {
        body() {
            return {
                last_name: this.inputs.last_name.value,
                first_name: this.inputs.first_name.value,
                email: this.inputs.email.value,
                company: this.inputs.company.value,
                tel: this.inputs.tel.value,
                message: this.inputs.message.value
            }
        }
    },
    methods: {
        send() {
            this.sending = true
            post('/contact', Object.assign({}, this.body, honeypot()))
                .then((data) => {
                    if (data.errors) {
                        this.success = false
                        this.unavailable = false
                        for (let input in this.inputs) {
                            if (Object.keys(data.errors).includes(input)) {
                                this.inputs[input].error = data.errors[input][0]
                            } else {
                                this.inputs[input].error = ''
                            }
                        }
                    } else {
                        this.success = true
                        this.unavailable = false
                        this.inputs = structuredClone(default_inputs)
                    }
                    this.sending = false
                })
                .catch((error) => {
                    this.success = false
                    this.unavailable = true
                    this.sending = false
                })
        }
    }
}
</script>